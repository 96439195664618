// @ts-check

// Generate a random uuid for pageview
// use timestamp for local development with aliased plusallt url
let pageViewId;
if ("randomUUID" in crypto) {
  pageViewId = crypto.randomUUID();
} else {
  pageViewId = `${Date.now()}`;
}

/**
 * @param {{
 *  path: string,
 *  contentId: string,
 *  id?: string,
 *  index?: number,
 *  publications?: string[],
 *  ignoreList?: string[],
 *  options?: import('../../../element.d.ts').PoofOptions
 * }} params
 * @returns {Promise<string | undefined>}
 */
const resolvePoofWidget = async (params) => {
  const response = await fetch("/api/resolve-poof-content/", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...params,
      pageViewId,
    }),
  });

  if (response.ok) {
    return await response.text();
  }

  return;
};

export default resolvePoofWidget;
