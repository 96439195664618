// @ts-check
import { trackImpression } from "@bonniernews/generika-tracking";

import resolvePoofWidget from "../../api/resolve-poof-widget.js";

const TAG_NAME = "gen-poof-widget";

/** @typedef {import('../../../../element.d.ts').PoofOptions} PoofWidgetOptions */
/** @typedef {import('../../../../element.d.ts').Attributes} PoofWidgetAttributes */

const PoofWidget = class PoofWidget extends HTMLElement {
  /**
   * @type {Pick<PoofWidgetAttributes, "index" | "margin" | "threshold"> & {
   * fetchOn: PoofWidgetAttributes["fetch-on"],
   * contentId: PoofWidgetAttributes["content-id"],
   * publications: string[]
   * ignoreListSelector: PoofWidgetAttributes["ignore-list-selector"],
   * options: PoofWidgetOptions
   * }}
   */
  // @ts-ignore
  props;
  /**
   * @type {{
   *  isLoading: boolean,
   *  loadedWidget: boolean,
   *  impressionTracked: boolean,
   *  index: number
   * }}
   * */
  // @ts-ignore
  state;
  /** @type {IntersectionObserver} */
  // @ts-ignore
  observer;

  constructor() {
    super();

    this.initObserver = this.initObserver.bind(this);
    this.load = this.load.bind(this);
    this.handlePoof = this.handlePoof.bind(this);
    this.handleOnObserve = this.handleOnObserve.bind(this);
  }

  get ignoreList() {
    if (this.props.ignoreListSelector) {
      const teaserElements = Array
        .from(document.querySelectorAll(this.props.ignoreListSelector))
        .map((i) => /** @type {HTMLElement} */(i).dataset.articleId)
        .filter((i) => i);

      return /** @type {string[]} */(Array.from(new Set(teaserElements)));
    }

    return undefined;
  }

  connectedCallback() {
    this.initProps();
    this.initState();

    if (this.props.fetchOn === "intersect") {
      this.initObserver();
    } else if (this.props.fetchOn === "load") {
      this.load();
    }
  }

  /**
   * Initiate the props object from parsed attributes
   */
  initProps() {
    this.props = {
      fetchOn: /** @type {PoofWidgetAttributes["fetch-on"]}*/(this.getAttribute("fetch-on") || "intersect"),
      contentId: this.getAttribute("content-id") || "",
      margin: this.getAttribute("margin") || "500px",
      threshold: Number(this.getAttribute("threshold")) || 1.0,
      index: Number(this.getAttribute("index")) || 0,
      options: JSON.parse(this.getAttribute("options") || "{}"),
      ignoreListSelector: this.getAttribute("ignore-list-selector") || "",
      publications: JSON.parse(this.getAttribute("publications") || "[]"),
    };

    if (!this.props.contentId) {
      throw new Error(`${TAG_NAME} | missing required "content-id" attribute`);
    }
  }

  /**
   * Initiate the state object
   */
  initState() {
    this.state = {
      isLoading: false,
      loadedWidget: false,
      impressionTracked: false,
      index: 0,
    };
  }

  /**
   * Initiate observer and start observing
   */
  initObserver() {
    this.observer = new IntersectionObserver(this.handleOnObserve, {
      rootMargin: this.props.margin,
      threshold: this.props.threshold,
    });

    this.observer.observe(this);
  }

  /**
   * Observer callback to to handle calling poof handler
   * @param {IntersectionObserverEntry[]} entries
   * @returns
   */
  async handleOnObserve(entries) {
    const entry = entries[0];

    if (!(entry instanceof IntersectionObserverEntry)) {
      return;
    }

    if (
      entry.isIntersecting &&
      !this.state.isLoading
    ) {
      await this.load();
      this.observer.unobserve(this);
    }
  }

  async load() {
    if (!this.state.loadedWidget) {
      this.state.isLoading = true;

      // TODO: handle spinner/placeholder css
      this.classList.add("spinner");
      await this.handlePoof();
      this.classList.remove("spinner");

      this.state.isLoading = false;
      this.state.loadedWidget = true;
    }
  }

  /**
   * Handle fetching poof content and append HTML response into the document
   */
  async handlePoof() {
    try {
      const teasersDocument = await resolvePoofWidget({
        id: this.id,
        contentId: this.props.contentId,
        path: new URL(window.location.href).pathname,
        index: this.props.index,
        ignoreList: this.ignoreList,
        publications: this.props.publications,
        options: this.props.options,
      });

      if (teasersDocument) {
        const newContent = document
          .createRange()
          .createContextualFragment(teasersDocument);

        this.appendChild(newContent);

        if (!this.state.impressionTracked) {
          trackImpression(this, (payload) => {
            // @ts-ignore
            window.dataLayer = window.dataLayer || []; // Ska inte det här hanteras i paketet?
            // @ts-ignore
            window.dataLayer.push(payload);
            this.state.impressionTracked = true;
          });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
};

export {
  TAG_NAME,
  PoofWidget,
};
