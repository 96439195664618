export class BackButton extends HTMLElement {
  connectedCallback() {
    this.addEventListener("click", this.handleEvent);
  }

  handleEvent(event) {
    let referrer;
    try {
      referrer = new URL(document.referrer);
    } catch {
      return;
    }
    if (window.history.length > 1 && referrer && referrer.host === window.location.host &&
      referrer.pathname !== window.location.pathname) {
      event.preventDefault();
      window.location = referrer;
    }
  }
}
