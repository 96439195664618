/**
 * Creates a selector for grabbing elements only matching a certain
 * tracking type in their space-separated data-track attribute.
 *
 * @param {string} type
 * @returns {string}
 */
export default function trackingTypeSelector(type) {
  return `[data-track~="${type}"]`;
}
