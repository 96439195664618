// @ts-check
export default class TitleSourcesForm extends HTMLElement {
  connectedCallback() {
    this.addEventListener("submit", this);
  }

  /**
   * @param {Event} event
   */
  handleEvent(event) {
    if (event.type !== "submit") {
      return;
    }

    event.preventDefault();
    const form = /** @type {HTMLFormElement} */(event.target);

    const userSettingsCookie = document.cookie
      .split(";")
      .find((cookie) => cookie.includes("user_settings"))
      ?.split("=")
      ?.[1];

    const userSettings = /** @type {import('../../../../lib/routes/sync-user-settings.js').UserSettingsCookie} */(userSettingsCookie ? JSON.parse(decodeURIComponent(userSettingsCookie)) : {});
    const formData = Object.fromEntries(new FormData(form).entries());

    userSettings.updated = new Date().getTime();
    userSettings.settings = {
      ...userSettings.settings,
      titleSources: Object.entries(formData).filter(([ , value ]) => value === "on").map(([ key ]) => key),
    };

    const { environment } = window.gnabb;
    const isCloudEnv = environment !== "development" && environment !== "test";

    document.cookie = [
      `user_settings=${encodeURIComponent(JSON.stringify(userSettings))}`,
      "Path=/",
      "Max-Age=31536000",
      "SameSite=Lax",
      isCloudEnv ? "Secure;" : "",
    ].join("; ");
  }
}
