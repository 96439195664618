import HTMLParsedElement from "../helpers/html-parsed-element.js";

export class SourceToggleButton extends HTMLParsedElement {
  /** @type {HTMLElement} */addButton;
  /** @type {HTMLElement} */removeButton;

  parsedCallback() {
    this.addEventListener("click", this.handleEvent);
    this.input = this.querySelector("input");
    this.addButton = this.querySelector(".add-button");
    this.removeButton = this.querySelector(".remove-button");

    this.removeButton.addEventListener("mouseenter", this);
    this.removeButton.addEventListener("mouseleave", this);
  }

  /**
   * @param {Event} event
   */
  handleEvent(event) {
    switch (event.type) {
      case "click": {
        this.addButton.hidden = !this.addButton.hidden;
        this.removeButton.hidden = !this.removeButton.hidden;
        this.input.checked = this.addButton.hidden;

        break;
      }

      case "mouseenter": {
        if (!this.removeButton.hidden) {
          this.removeButton.innerHTML = "Ta bort";
        }

        break;
      }

      case "mouseleave": {
        if (!this.removeButton.hidden) {
          this.removeButton.innerHTML = "Tillagd";
        }

        break;
      }
    }
  }
}
