import "@bonniernews/generika-poof-widget";

import "./user-settings.js";

import { initDidomiPreferences } from "@bonniernews/generika-didomi/main.js";

import { initTracking } from "./tracking.js";
import { FeedbackButtons } from "./elements/feedback-buttons.js";
import { BackButton } from "./elements/back-button.js";
import { SourceToggleButton } from "./elements/source-toggle-button.js";
import TitleSourcesForm from "./elements/title-sources-form.js";

window.gnabb = window.gnabb || {};

initDidomiPreferences();
initTracking();
import("./rum-reporter.js")
  .then(({ initRumReporter }) => initRumReporter());

window.customElements.define("feedback-buttons", FeedbackButtons);
window.customElements.define("back-button", BackButton);
window.customElements.define("source-toggle-button", SourceToggleButton);
window.customElements.define("title-sources-form", TitleSourcesForm);
