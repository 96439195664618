import parsePayload from "./helpers/parse-payload.js";
import trackingTypeSelector from "./helpers/tracking-type-selector.js";

/**
 * @param callback {() => void}
 */
export default function initLinkClickTracking(callback) {
  document.addEventListener("click", ({ target }) => {
    const linkTarget = target.closest("a[href]");
    const type = "link-click";
    const apiTarget = linkTarget && linkTarget.closest(trackingTypeSelector(type));

    if (!apiTarget) {
      return;
    }

    const payload = parsePayload(type, apiTarget.dataset.trackPayload) || {};

    const eventPayload = {
      content: undefined,
      ...payload, // Add payload
      event: payload.event || "link click", // hard-coded for event type
    };

    callback(eventPayload);
  });
}
