import parsePayload from "./helpers/parse-payload.js";
import trackingTypeSelector from "./helpers/tracking-type-selector.js";

/**
 * @param element {HTMLElement | null}
 * @param callback {() => void}
 */
export default function trackImpression(element, callback) {
  /** @type {HTMLElement | null} */
  const trackElement = element.querySelector(trackingTypeSelector("impression"));
  if (!trackElement) return;
  const trackPayload = trackElement.dataset.trackPayload || {};
  const parsedPayload = parsePayload("impression", trackPayload);

  // @ts-ignore
  const payload = {
    content: undefined,
    ...parsedPayload,
    event: parsedPayload.event,
  };

  callback(payload);
}
