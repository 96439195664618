import { initLinkClickTracking } from "@bonniernews/generika-tracking";

import { throttle } from "./helpers/throttle.js";

// General note: the dataLayer will always be populated regardless of didomi-consent status, GTM will only store data when consent is given
export function initTracking() {
  if (!window.gnabb?.trackingData?.content) {
    return;
  }
  window.gnabb.trackingData.content.location = document.location.href;
  window.didomiOnReady.push(() => {
    trackPageView();
    initScrollDepthTracking();
    initLinkClickTracking((payload) => {
      window.dataLayer.push({
        ...window.gnabb.trackingData,
        ...payload,
      });
    });
    initToggleTracking();
  });
}
/**
 * Creates a selector for grabbing elements only matching a certain
 * tracking type in their space-separated data-track attribute.
 *
 * @param {string} type
 * @returns {string}
 */
function trackingTypeSelector(type) {
  return `[data-track~="${type}"]`;
}

/**
 *
 * @param {string} type
 * @param {Record<string, any>} payload
 */
function parsePayload(type, payload) {
  try {
    const data = JSON.parse(payload);
    return type in data && data[type];
  } catch {
    return;
  }
}

function trackPageView() {
  const trackingData = window.gnabb.trackingData;
  if (trackingData?.content) {
    trackingData.content.title = document.title;
    trackingData.content.referrer = document.referrer;
  }

  const pageView = {
    ...trackingData,
    event: "page view",
  };

  window.dataLayer.push(pageView);
  window.didomiEventListeners.push({
    event: "consent.changed",
    listener: () => window.dataLayer.push(pageView),
  });
}

function initScrollDepthTracking() {
  if (!document.body.dataset.trackScrollDepth) return;

  let milestones = [ 25, 50, 75, 90, 100 ];

  window.addEventListener("scroll", throttle(() => {
    const scrollPercentage = Math.floor((window.scrollY / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) * 100);
    milestones.forEach((value, index) => {
      if (scrollPercentage >= value) {
        window.dataLayer.push({
          event: "start page scroll",
          eventParams: {
            category: "start page",
            scrollDepth: `${value}`,
            scrollMeasure: "percent",
          },
        });
        milestones = milestones.slice(index + 1, milestones.length);
      }
    });
  }, 100));
}

function initToggleTracking() {
  document.addEventListener("toggle", (event) => {
    const { target } = event;
    if (!(target && target.matches(trackingTypeSelector("toggle")))) {
      return;
    }
    const payload = parsePayload("toggle", target.dataset.trackPayload) || {};
    const action = target.open ? "expand" : "collapse";
    const trackingData = {
      event: `${payload.eventPrefix} ${action}`,
      ...payload,
      engagement: {
        ...payload.engagement,
        type: action,
      },
    };
    window.dataLayer.push(trackingData);
  }, true);
}
